import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import SEO from '../components/SEO'

export default class EventsPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Layout>
        <SEO
          isBlogPost={false}
          postData={{
            title: 'Eventos de Chicas al Frente', 
            description: 'Participa en el evento del mes o consulta eventos anteriores realizados por Chicas al Frente'}}
          postImage={posts && posts[0].node.fields.cover_image_rel ? posts[0].node.fields.cover_image_rel.childImageSharp.fluid.src : ''}
          slug="/eventos"
        />
        <PostList 
          posts={posts} 
          title="Evento del Mes" 
          pageContext={pageContext}/>
      </Layout>
    )
  }
}

EventsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
    pageContext: PropTypes.shape({
      currentPage: PropTypes.number,
      numPages: PropTypes.number,
    }),
  }),
}

export const pageQuery = graphql`
  query EventsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      skip: $skip, limit: $limit,
      filter: { frontmatter: { templateKey: { eq: "event" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
            cover_image_rel {
              childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 400) {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                }
              }
            }
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
